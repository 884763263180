import MaterialIconsWoff from '../assets/fonts/icons/material-icons-regular.woff'
import MaterialIconsWoff2 from '../assets/fonts/icons/material-icons-regular.woff2'

import FreightSansWoff from '../assets/fonts/freight-sans/Freight-SansBook.woff'
import FreightSansWoff2 from '../assets/fonts/freight-sans/Freight-SansBook.woff2'

import FreightSansBoldWoff from '../assets/fonts/freight-sans/Freight-SansBold.woff'
import FreightSansBoldWoff2 from '../assets/fonts/freight-sans/Freight-SansBold.woff2'

import FreightSansMediumWoff from '../assets/fonts/freight-sans/Freight-SansMedium.woff'
import FreightSansMediumWoff2 from '../assets/fonts/freight-sans/Freight-SansMedium.woff2'

import CopernicusWoff from '../assets/fonts/copernicus/CopernicusWeb-Book.woff'
import CopernicusWoff2 from '../assets/fonts/copernicus/CopernicusWeb-Book.woff2'

import CopernicusItalicWoff from '../assets/fonts/copernicus/CopernicusWeb-BookItalic.woff'
import CopernicusItalicWoff2 from '../assets/fonts/copernicus/CopernicusWeb-BookItalic.woff2'

import CopernicusBoldWoff from '../assets/fonts/copernicus/CopernicusWeb-Bold.woff'
import CopernicusBoldWoff2 from '../assets/fonts/copernicus/CopernicusWeb-Bold.woff2'

export const fontFace = (fontName, woff, woff2, fontWeight = 'normal', fontStyle = 'normal') => `
	@font-face {
		font-family: '${ fontName }';
		src:  url('${ woff }') format('woff'),
					url('${ woff2 }') format('woff2');
		font-weight: ${ fontWeight };
		font-style: ${ fontStyle };
		font-display: swap;
	}
`
export const MaterialIcons = 'Material Icons'
export const MaterialIconsFont = fontFace(MaterialIcons, MaterialIconsWoff, MaterialIconsWoff2)

export const FreightSans = 'Freight Sans'
export const FreightSansFont = fontFace(FreightSans, FreightSansWoff, FreightSansWoff2)
export const FreightSansBoldFont = fontFace(FreightSans, FreightSansBoldWoff, FreightSansBoldWoff2, 700)
export const FreightSansBoldItalicFont = fontFace(FreightSans, FreightSansMediumWoff, FreightSansMediumWoff2, 500)

export const Copernicus = 'Copernicus'
export const CopernicusFont = fontFace(Copernicus, CopernicusWoff, CopernicusWoff2)
export const CopernicusItalicFont = fontFace(Copernicus, CopernicusItalicWoff, CopernicusItalicWoff2, 'normal', 'italic')
export const CopernicusBoldFont = fontFace(Copernicus, CopernicusBoldWoff, CopernicusBoldWoff2, 700)
