import { lighten, darken, rgba } from 'polished'

export const black = '#000'
export const white = '#fff'

// Site Specific Colors
export const yellow = '#FACC02'
export const red = '#D56B33'
export const blue = '#51B4BA'
export const darkBlue = '#073742'
export const grey1 = '#F7FAFC'
export const grey2 = '#EEF4F8'
export const grey3 = '#DDE8F0'
export const grey4 = '#BCD0DF'

// Basic Colors
export const transparent = 'transparent'
export const currentcolor = 'currentcolor'
export const bgColor = white
export const mainColor = blue
export const alert = red
export const notify = yellow
export const success = blue
export const textColor = darkBlue
export const lightTextColor = rgba(textColor, 0.4)
export const lightGrey = grey1
export const hrColor = rgba(textColor, 0.1)

// Color Variations
export const mainColorDarken = darken(0.07, mainColor)
export const mainColorLighten = lighten(0.07, mainColor)
