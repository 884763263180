import * as colors from './colors'
import { lighten, darken } from 'polished'

// Themes (ThemeSelector Component)
export const themes = {
	default: {
		color: colors.textColor,
		background: colors.bgColor,
		hoverColor: colors.mainColor,
		buttonTheme: 'default',
		buttonThemeSecondary: 'darkBlue'
	},
	lightGrey: {
		color: colors.textColor,
		background: colors.lightGrey,
		hoverColor: colors.textColor,
		buttonTheme: 'default',
		buttonThemeSecondary: 'darkBlue'
	},
	blue: {
		color: colors.bgColor,
		background: colors.mainColor,
		hoverColor: colors.bgColor,
		buttonTheme: 'white',
		buttonThemeSecondary: 'darkBlue'
	},
	darkBlue: {
		color: colors.bgColor,
		background: colors.darkBlue,
		hoverColor: colors.bgColor,
		buttonTheme: 'white',
		buttonThemeSecondary: 'default'
	},
}

// Button Themes
export const buttonThemes = {
	default: {
		color: colors.bgColor,
		background: colors.mainColor,
		hoverColor: colors.bgColor,
		hoverBackground: lighten(0.07, colors.mainColor)
	},
	transparent: {
		color: colors.lightTextColor,
		background: 'transparent',
		hoverColor: colors.textColor,
		hoverBackground: 'transparent'
	},
	currentcolor: {
		color: colors.textColor,
		background: 'currentcolor',
		hoverColor: 'currentcolor',
		hoverBackground: 'currentcolor'
	},
	currentcolorOutlined: {
		color: 'currentcolor',
		background: 'transparent',
		hoverColor: colors.textColor,
		hoverBackground: 'currentcolor',
		borderColor: 'currentcolor'
	},
	darkBlue: {
		color: colors.bgColor,
		background: colors.darkBlue,
		hoverColor: colors.white,
		hoverBackground: darken(0.07, colors.mainColor)
	},
	white: {
		color: colors.textColor,
		background: colors.white,
		hoverColor: colors.mainColor,
		hoverBackground: colors.lightGrey
	},
	lightGrey: {
		color: colors.textColor,
		background: colors.lightGrey,
		hoverColor: colors.textColor,
		hoverBackground: darken(0.07, colors.lightGrey)
	},
	mainColor: {
		color: colors.bgColor,
		background: colors.mainColor,
		hoverColor: colors.bgColor,
		hoverBackground: lighten(0.07, colors.mainColor)
	},
	textColor: {
		color: colors.bgColor,
		background: colors.textColor,
		hoverColor: colors.bgColor,
		hoverBackground: lighten(0.2, colors.textColor)
	}
}

// Input Themes
export const inputThemes = {
	default: {
		color: colors.textColor,
		placeholderColor: colors.lightTextColor,
		background: colors.transparent,
		accentColor: colors.mainColor,
		hoverColor: colors.bgColor,
		borderColor: colors.grey4,
		hoverBorderColor: colors.textColor,
		focusBorderColor: colors.mainColor,
		hoverBackground: colors.transparent,
		focusBackground: colors.transparent,
	},
	textColorLight: {
		color: colors.white,
		placeholderColor: colors.grey4,
		background: lighten(0.075, colors.textColor),
		accentColor: colors.mainColor,
		hoverColor: lighten(0.1, colors.textColor),
		borderColor: 'transparent',
		hoverBorderColor: 'transparent',
		focusBorderColor: 'transparent',
		hoverBackground: lighten(0.1, colors.textColor),
		focusBackground: lighten(0.1, colors.textColor),
	}
}

export default themes
